// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.investor-stats {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 55px;
  padding: 5px 15px;
  background: white;
  border: 1px solid #c8d0d4;
}
.investor-stats .investor-stats__stats {
  display: flex;
  gap: 20px;
}
.investor-stats .investor-stats__household {
  display: flex;
  align-items: center;
  gap: 20px;
}
.investor-stats .investor-stats__household .household__add-member {
  display: flex;
  gap: 5px;
}
.investor-stats .investor-stats__household .household__add-member .btn-join {
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 3px;
}
.investor-stats .investor-stats__household .household__add-member .form-group.search {
  float: none;
  width: 185px;
  margin-bottom: 0;
}
.investor-stats .investor-stats__household .household__add-member .form-group.search .react-autosuggest__container input {
  padding: 5px 10px;
  border: 1px solid #c8d0d4;
  border-radius: 3px;
  font-size: 0.9rem;
}
.investor-stats .investor-stats__household .household__add-member .form-group.search .react-autosuggest__container input:focus {
  border: 1px solid #c8d0d4;
  outline: none;
}
.investor-stats .investor-stats__household .household__badge {
  display: flex;
  align-items: center;
  font-weight: 400;
  gap: 5px;
}
.investor-stats .investor-stats__household .household__badge .household-icon {
  height: 14px;
  fill: white;
}`, "",{"version":3,"sources":["webpack://./src/app/components/advisor/investors/details/overview/investor-stats/styles.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,iBAAA;EACA,iBAAA;EACA,yBAAA;AADF;AAGE;EACE,aAAA;EACA,SAAA;AADJ;AAIE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAFJ;AAII;EACE,aAAA;EACA,QAAA;AAFN;AAIM;EACE,mBAAA;EACA,kBAAA;EACA,kBAAA;AAFR;AAKM;EACE,WAAA;EACA,YAAA;EACA,gBAAA;AAHR;AAMU;EACE,iBAAA;EACA,yBAAA;EACA,kBAAA;EACA,iBAAA;AAJZ;AAOU;EACE,yBAAA;EACA,aAAA;AALZ;AAWI;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,QAAA;AATN;AAWM;EACE,YAAA;EACA,WAAA;AATR","sourcesContent":["@import 'assets/scss/commons/variables';\n\n.investor-stats {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  position: relative;\n  height: 55px;\n  padding: 5px 15px;\n  background: white;\n  border: 1px solid $graphite-grayish-blue-2;\n\n  .investor-stats__stats {\n    display: flex;\n    gap: 20px;\n  }\n\n  .investor-stats__household {\n    display: flex;\n    align-items: center;\n    gap: 20px;\n\n    .household__add-member {\n      display: flex;\n      gap: 5px;\n\n      .btn-join {\n        padding-right: 20px;\n        padding-left: 20px;\n        border-radius: 3px;\n      }\n\n      .form-group.search {\n        float: none;\n        width: 185px;\n        margin-bottom: 0;\n\n        .react-autosuggest__container {\n          input {\n            padding: 5px 10px;\n            border: 1px solid $graphite-grayish-blue-2;\n            border-radius: 3px;\n            font-size: 0.9rem;\n          }\n\n          input:focus {\n            border: 1px solid $graphite-grayish-blue-2;\n            outline: none;\n          }\n        }\n      }\n    }\n\n    .household__badge {\n      display: flex;\n      align-items: center;\n      font-weight: 400;\n      gap: 5px;\n\n      .household-icon {\n        height: 14px;\n        fill: white;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
